import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { history } from '../../helpers/history';

const useStyles = makeStyles((theme) => ({
	appBar: {
		backgroundColor: "#10101a",
    marginBottom: theme.spacing(3)
	},
	title: {
		flexGrow: 1
	},
  color: {
    color: "#fff"
  },
  button: {
    textTransform: "none"
  }
}));

function Header() {
  const classes = useStyles();

  const backToFirstPage = () => {
    history.push("/");
  }

  return (
    <AppBar className={classes.appBar} position="relative">
			<Toolbar id="back-to-top-anchor">
        <Button
          onClick={backToFirstPage}
          className={classes.button}
          data-testid="back-button"
          >
          <Typography variant="h5" className={classes.title}>
            r/pics
          </Typography>
        </Button>
			</Toolbar>
		</AppBar>
  )
};

export default Header;