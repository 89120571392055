import { MiddlewareAPI, Dispatch, Middleware, AnyAction } from 'redux';


const picsMiddleware: Middleware =(store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (action: any) => {

  switch (action.type) {
    case 'pics/fetchNew/fulfilled':
      const currentState = store.getState();
      const currentChildren = currentState.pics.data.children;

      const payload = action.payload;
      const newChildren = payload.children;

      payload.children = currentChildren.concat(newChildren);

      action.payload = payload;
      next(action);
    break;
    default:
      return next(action);
  }
}


export default picsMiddleware;