import React from "react";
import { 
  CircularProgress, 
  Container,
  Fab,
  Grid,
  makeStyles,
  Theme 
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { 
  after, 
  fetchNew, 
  picsLoading, 
  selectResult 
} from './picsSlice';
import Thumbnail from '../../components/Thumbnail/Thumbnail';
import ScrollTop from '../../components/ScrollToTop/ScrollToTop';
import { KeyboardArrowUp } from '@material-ui/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonThumbnail from '../../components/Thumbnail/SkeletonThumbnail';
import PictureFilter from './PictureFilter';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    flexDirection: "column",
  },
  grid: {
    width: "100%"
  },
  gridItem: {
    margin: '1rem 0',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  infiniteDiv: {
    overflow: "unset !important",
    width: "100%"
  }
}));

function PictureViewer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useAppSelector(picsLoading);
  const afterState = useAppSelector(after);
  const filtered = useAppSelector(selectResult);

  return (
      <Container
        maxWidth="md"
        className={classes.root}
        >
        <PictureFilter />
        {loading ? <CircularProgress data-testid="loading-pics"/> 
        : <InfiniteScroll
            style={{ width: "100%" }}
            className={classes.infiniteDiv}
            dataLength={filtered.length}
            next={() => dispatch(fetchNew(afterState))}
            hasMore={filtered.length >= 25 ? true : false}
            loader={<SkeletonThumbnail />}
            >
            <Grid
              container
              className={classes.grid}
              >
              {filtered.map((pic: any, idx: number) => (
                <Grid
                  item
                  xs={12}
                  className={classes.gridItem}
                  key={idx}
                  >
                  <Thumbnail 
                    id={pic.data.id}
                    title={pic.data.title}
                    created_utc={pic.data.created_utc}
                    author={pic.data.author}
                    image={pic.data.thumbnail}
                    ups={pic.data.ups}
                    comments={pic.data.num_comments}
                    all_awards={pic.data.all_awardings}
                  />
                </Grid>
              ))}
          </Grid>
        </InfiniteScroll>}
      <ScrollTop>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
    </Container>
  )
};

export default PictureViewer;