import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { convertUnixTime } from '../../helpers/convertTime';
import { Box, CardActionArea, Typography } from '@material-ui/core';
import { ArrowUpward, ChatBubble } from '@material-ui/icons';
import { abbreviateNumber } from '../../helpers/kFormatter';
import { useHistory } from 'react-router';
import Awards from '../Awards/Awards';

const useStyles = makeStyles((theme: Theme) =>({
  root: {
    width: "100%"
  },
  title: {
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 2,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  titleImg: {
    height: 100,
    width: 100
  },
  detailsContainer: {
    display: "flex"
  },
  details: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2)
  },
  awards: {
    display: "flex",
    alignItems: "center",
    marginRight: "5px"
  }
}));

interface ThumbnailProps {
  id: string;
  title: string;
  created_utc: number;
  author: string;
  image: string;
  ups: number;
  comments: number;
  all_awards: any[];
}

function Thumbnail({
    id,
    title, 
    created_utc, 
    author, 
    image,
    ups,
    comments,
    all_awards
  }: ThumbnailProps) {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/post/${id}`);
  }

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={handleClick}
        >
        <CardHeader 
          avatar={
            <img src={image} alt={title} className={classes.titleImg} />
          }
          title={
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>
          }
          subheader={
            <>
              <Box>
                Posted by {author} {convertUnixTime(created_utc)}
              </Box>
              <Box className={classes.detailsContainer}>
                <div className={classes.details}>
                  <ArrowUpward /> {abbreviateNumber(ups)}
                </div>
                <div className={classes.details}>
                <ChatBubble /> {abbreviateNumber(comments)}
                </div>
                <div className={classes.details}>
                  <Awards all_awards={all_awards} />
                </div>
              </Box>
            </>
          }
        />
      </CardActionArea>
    </Card>
  )
}

export default Thumbnail;