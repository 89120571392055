import { createStore, applyMiddleware } from '@reduxjs/toolkit';
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import picsMiddleware from './picsMiddleware';

const middleware = [thunk, picsMiddleware]

const composedEnhancer = composeWithDevTools(applyMiddleware(...middleware));

export const store = createStore(rootReducer, composedEnhancer);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch