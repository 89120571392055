import React from "react";
import { Box, makeStyles, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { filter, filterText } from './picsSlice';

const useStyles = makeStyles(() => ({
  root: {
    width: 500
  }
}));

function PictureFilter() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useAppSelector(filterText);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(filter(evt.target.value));
  }

  return (
    <Box>
      <TextField
        className={classes.root}
        variant="outlined"
        margin="normal"
        fullWidth
        id="Filter by title"
        label="Filter by title"
        autoFocus
        value={filters}
        onChange={handleChange}
      />
    </Box>
  )
}

export default PictureFilter;