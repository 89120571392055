import React, { useEffect } from 'react';
import { Container, CssBaseline, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import Header from './components/Header/Header';
import Routes from './containers/Router/Router';
import "./App.css";
import { useDispatch } from 'react-redux';
import { fetchPics } from './containers/PictureViewer/picsSlice';

const theme = createTheme({
  palette: {
    type: "dark"
  }
});

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPics());  
  },[dispatch]);

  return (
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <Container
        maxWidth={false}
        disableGutters
        style={{
          minHeight: "100vh"
        }}
        >
          <Header 
            />
          <Routes />
      </Container>
  </ThemeProvider>
  );
}

export default App;