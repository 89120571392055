import axios from 'axios';

const HttpClient = axios.create({
  baseURL: 'https://www.reddit.com/r/pics',
  timeout: 80000,
  headers: {
    'Content-Type': 'application/json',
  },
});

HttpClient.interceptors.response
  .use(
    (response) => {
      return response.data
    },
    (error) => {
      return Promise.reject(error);
    },
  );

export default HttpClient;
