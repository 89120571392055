import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    title: {
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 2,
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
    },
    titleImg: {
      height: 100,
      width: 100
    },
    detailsContainer: {
      display: "flex"
    },
    details: {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(2)
    },
    awards: {
      display: "flex",
      alignItems: "center",
      marginRight: "5px"
    }
  }),
);

function SkeletonThumbnail() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader 
        avatar={
          <Skeleton variant="rect" className={classes.titleImg} />
        }
        title={
          <Skeleton height={20} width="40%" style={{ marginBottom: 6 }} />
        }
        subheader={
          <>
            <Box>
              <Skeleton height={15} width="15%" />
            </Box>
            <Box className={classes.detailsContainer}>
              <Skeleton height={15} width="10%" />
            </Box>
          </>
        }
      />
    </Card>
  )
}

export default SkeletonThumbnail;