import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import PictureViewer from '../PictureViewer/PictureViewer';
import PostsView from '../PostsView/PostView';
import { history } from '../../helpers/history';

const Routes = () => (
  <ErrorBoundary>  
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={PictureViewer} />
        <Route path="/post/:id" component={PostsView} />
        <Route path="*" render={() => (<h1>ERROR 404 Page doesnt exist</h1>)} />
      </Switch>
    </Router>
  </ErrorBoundary>
);

export default Routes;