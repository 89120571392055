import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from '../../app/store';
import client from "../../services/client";

interface PicsState {
  filterText: string;
  selectedId: string;
  data: any;
  loading: boolean;
}

const initialState: PicsState = {
  filterText: "",
  selectedId: "",
  data: {
    children: []
  },
  loading: false,
}

export const fetchPics = createAsyncThunk('pics/fetchPics', async () => {
  const response = await client.get(`/.json?`);
  return response.data;
});

export const fetchNew = createAsyncThunk('pics/fetchNew', async (after?: string) => {
  const response = await client.get(`/.json?after=${after}`);
  return response.data;
});

const picsReducer = createSlice({
  name: 'pics',
  initialState,
  reducers: {
    filter: (state, action) => {
      state.filterText = action.payload
    },
    selectId: (state, action) => {
      state.selectedId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPics.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPics.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(fetchNew.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.data = payload;
    });
  }
});

export const after = (state: RootState) => state.pics.data.after;
export const before = (state: RootState) => state.pics.data.before;
export const picsLoading = (state: RootState) => state.pics.loading;
export const picsData = (state: RootState) => state.pics.data.children;
export const filterText = (state:RootState) => state.pics.filterText;
export const selectedId = (state: RootState) => state.pics.selectedId;

export const selectResult = createSelector([picsData, filterText], (data, text) => {
  return data.filter((item: any) => item.data.title.toLowerCase().includes(text));
});

export const selectedItem = createSelector([selectResult, selectedId], (data, id) => {
  return data.find((item: any) => item.data.id === id);
});

export const { filter, selectId } = picsReducer.actions;
export default picsReducer.reducer;