import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  awardsContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  awards: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

interface AwardsProp {
  all_awards: any[];
  full?: boolean;
}

function Awards({all_awards, full}: AwardsProp) {
  const classes = useStyles();

 if(!full) {
  if (all_awards.length > 4) {
    const slicedAward = all_awards.slice(0, 5);
    return (
      <div className={classes.awardsContainer}>
        {slicedAward.map((award, idx) => (
          <div className={classes.awards} key={idx}>
            <img src={award.icon_url} alt={award.id} height="20" />
            {award.count}
          </ div>
        ))}
        {" "} & {all_awards.length - 4} more
      </div>
    )
  }
 }

  return (
    <div className={classes.awardsContainer}>
      {
        all_awards.map((award, idx) => (
          <div className={classes.awards} key={idx}>
            <img src={award.icon_url} alt={award.id} height="20" />
            {award.count}
          </ div>
        ))
      }
    </div>
  )
}

export default Awards;