import React from "react";
import { 
  Avatar, 
  Box, 
  Card, 
  CardActionArea, 
  CardHeader, 
  CardMedia, 
  makeStyles, 
  Theme, 
  Typography 
} from '@material-ui/core';
import { ArrowUpward, ChatBubble } from '@material-ui/icons';
import { abbreviateNumber } from '../../helpers/kFormatter';
import { convertUnixTime } from '../../helpers/convertTime';
import Awards from '../Awards/Awards';

const useStyles = makeStyles((theme: Theme) => ({
  media: {
    objectFit: "contain",
    height: "auto",
    maxHeight: 800,
    marginBottom: theme.spacing(3)
  },
  detailsContainer: {
    display: "flex"
  },
  subredditTitle: {
    color: theme.palette.grey[100],
    marginRight: theme.spacing(1),
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  firstHeader: {
    paddingBottom: 0
  },
  secondHeader: {
    paddingTop: theme.spacing(2)
  },
  details: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    overflow: "warp",
    fontSize: "1rem"
  },
  awards: {
    display: "flex",
    alignItems: "center",
    marginRight: "5px"
  },
  avatarImg: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  author: {
    color: theme.palette.grey[500],    
    textDecoration: "none",
  }
}));

interface ViewCardProps {
  author: string;
  title: string;
  url: string;
  created_utc: number;
  all_awards: any[];
  ups: number;
  comments: number;
  permalink: string;
}

function ViewCard({
  author,
  title,
  url,
  created_utc,
  all_awards,
  ups,
  comments,
  permalink,
}: ViewCardProps) {
  const classes = useStyles();

  const handleOnClick = () => {
    window.open(`https://www.reddit.com${permalink}`)
  }

  return (
    <Card>
      <CardActionArea
        onClick={handleOnClick}
        >
        <CardHeader 
          className={classes.firstHeader}
          avatar={
            <Avatar 
              aria-label="r/pics" 
              src="https://b.thumbs.redditmedia.com/VZX_KQLnI1DPhlEZ07bIcLzwR1Win808RIt7zm49VIQ.png" 
              className={classes.avatarImg}
              />
          }
          title={
            <Typography 
              variant="caption"
              >
              <a 
                href="https://www.reddit.com/r/pics/" 
                target="_blank" 
                rel="noreferrer"
                className={classes.subredditTitle}
                >
                r/pics
              </a>
            </Typography>
          }
          subheader={
            <div>
              <Typography variant="caption" className={classes.author}>
                Posted by <a href={`https://www.reddit.com/user/${author}`}
                  target="_blank" 
                  className={classes.author}
                  rel="noreferrer"
                  >
                  u/{author} 
                </a>
                {" "}
                {convertUnixTime(created_utc)}
                <Box className={classes.details}>
                  <Awards 
                    all_awards={all_awards} 
                    full
                    />
                </Box>
              </Typography>
            </div>
          }
          />
        <CardHeader 
          className={classes.secondHeader}
          title={title}
          subheader= {
            <Box className={classes.detailsContainer}>
              <div className={classes.details}>
                <ArrowUpward /> {abbreviateNumber(ups)} Upvotes
              </div>
              <div className={classes.details}>
                <ChatBubble /> {abbreviateNumber(comments)} Comments
              </div>
            </Box>
          }
          />
        <CardMedia 
          component="img"
          src={url}
          className={classes.media}
        />
      </CardActionArea>
    </Card>
  )
}

export default  ViewCard;