import React, { useEffect } from "react";
import { Container, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useAppSelector } from '../../app/hooks';
import ViewCard from '../../components/ViewCard/ViewCard';
import { selectResult, selectId } from '../PictureViewer/picsSlice';

type IdParams = {
  id: string;
}

function PostsView() {
  const { id } = useParams<IdParams>();
  const dispatch = useDispatch();
  const items= useAppSelector(selectResult);
  const item = items.find((data: any) => data.data.id === id);

  useEffect(() => {
    dispatch(selectId(id));
  },[dispatch, id]);

  return (
    <Container
      maxWidth="md"
      >
        {item ? (
          <ViewCard 
            author={item.data.author}
            title={item.data.title}
            url={item.data.url}
            created_utc={item.data.created_utc}
            all_awards={item.data.all_awardings}
            ups={item.data.ups}
            comments={item.data.num_comments}
            permalink={item.data.permalink}
            />
        ): <Typography variant="h2">It's Empty here...</Typography>}
      </Container>
  )
}

export default PostsView;